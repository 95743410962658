import { useState } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import { useCart } from "../../contexts/Cart.context";
import { imgLoading3 } from "../../utils/Images";
import CartItem from "./CartItem";
import CartSummary from "./CartSummary";

const CartPage = () => {
  const { cart } = useCart();
  const [isEditinCart, setIsEditinCart] = useState(false);

  return (
    <div className="order py-5">
      <div className="container">
        <Breadcrumb />
        <h2 className="title">My Cart</h2>

        {!cart ? (
          <img src={imgLoading3} className="d-block m-auto" height="80" />
        ) : (
          ""
        )}

        {cart ? (
          cart.items.length == 0 ? (
            <div className="empty-box">
              <svg
                width="40"
                height="40"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.75 1.75H3.58333L3.95 3.58333L1.75 1.75ZM5.41667 10.9167H14.5833L18.25 3.58333H3.95L5.41667 10.9167ZM5.41667 10.9167L3.95 3.58333L5.41667 10.9167ZM5.41667 10.9167L3.31475 13.0186C2.73725 13.5961 3.14608 14.5833 3.96283 14.5833H14.5833L5.41667 10.9167ZM14.5833 14.5833C14.0971 14.5833 13.6308 14.7765 13.287 15.1203C12.9432 15.4641 12.75 15.9304 12.75 16.4167C12.75 16.9029 12.9432 17.3692 13.287 17.713C13.6308 18.0568 14.0971 18.25 14.5833 18.25C15.0696 18.25 15.5359 18.0568 15.8797 17.713C16.2235 17.3692 16.4167 16.9029 16.4167 16.4167C16.4167 15.9304 16.2235 15.4641 15.8797 15.1203C15.5359 14.7765 15.0696 14.5833 14.5833 14.5833ZM7.25 16.4167C7.25 16.9029 7.05685 17.3692 6.71303 17.713C6.36921 18.0568 5.9029 18.25 5.41667 18.25C4.93044 18.25 4.46412 18.0568 4.1203 17.713C3.77649 17.3692 3.58333 16.9029 3.58333 16.4167C3.58333 15.9304 3.77649 15.4641 4.1203 15.1203C4.46412 14.7765 4.93044 14.5833 5.41667 14.5833C5.9029 14.5833 6.36921 14.7765 6.71303 15.1203C7.05685 15.4641 7.25 15.9304 7.25 16.4167V16.4167Z"
                  stroke="#8F8F8F"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>Cart is Empty</span>
            </div>
          ) : (
            <div
              className={`py-5 text-start m-auto ${
                isEditinCart ? "is-loading" : ""
              }`}
            >
              <div className="order-box px-4 py-3">
                <div id="order_details" className="order-details d-block mt-3">
                  <div className="my-3">
                    <table
                      className="text-center w-100"
                      style={{
                        tableLayout: "fixed",
                        borderCollapse: "collapse",
                      }}
                    >
                      <thead>
                        <tr>
                          <th className="text-start" style={{ width: "45%" }}>
                            Item Name
                          </th>
                          {/* <th>SKU</th> */}
                          {/* <th>Part Number</th> */}
                          <th>Quantity</th>
                          <th>Unit Price</th>
                          <th>Subtotal</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cart && cart.items
                          ? cart.items.map((item) => {
                              return (
                                <CartItem
                                  isEditinCart={isEditinCart}
                                  setIsEditinCart={setIsEditinCart}
                                  key={item.id}
                                  item={item}
                                />
                              );
                            })
                          : ""}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <CartSummary />
            </div>
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default CartPage;
