import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";

function DealOfDay(props) {
  const [timer, setTimer] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  function daysBetween(date1, date2) {
    var one_day = 1000 * 60 * 60 * 24;
    var date1_ms = date1.getTime();
    var date2_ms = date2.getTime();
    var difference_ms = date2_ms - date1_ms;
    return Math.round(difference_ms / one_day);
  }

  function startCountdown() {
    var interval;
    interval = setInterval(function () {
      let newTimer = { ...timer };
      if (newTimer.seconds > 0) {
        newTimer.seconds--;
      } else {
        // Seconds is zero - check the minutes
        if (newTimer.minutes > 0) {
          newTimer.minutes--;
          newTimer.seconds = 59;
        } else {
          // Minutes is zero, check the hours
          if (newTimer.hours > 0) {
            newTimer.hours--;
            newTimer.minutes = 59;
            newTimer.seconds = 59;
          } else {
            // Hours is zero
            newTimer.days--;
            newTimer.hours = 23;
            newTimer.minutes = 59;
            newTimer.seconds = 59;
          }
        }
      }

      setTimer(newTimer);
    }, 1000);
  }

  useEffect(() => {
    if (!props.special) return;

    var targetDate = new Date(props.special.start_date);
    var now = new Date(props.special.end_date);

    let newTimer = { ...timer };

    newTimer.days = daysBetween(now, targetDate);
    var secondsLeft = props.special.remaining_time;
    newTimer.hours = Math.floor(secondsLeft / 60 / 60);
    secondsLeft = secondsLeft - newTimer.hours * 60 * 60;
    newTimer.minutes = Math.floor(secondsLeft / 60);
    secondsLeft = secondsLeft - newTimer.minutes * 60;
    newTimer.seconds = Math.floor(secondsLeft);

    console.log(newTimer);
    setTimer(newTimer);
    startCountdown();
  }, [props.special]);

  useEffect(() => {
    console.log(timer);
  }, [props.special]);

  return (
    <>
      <div className="counter d-flex justify-content-between">
        {props.special ? (
          <div className="counter-wheel position-relative me-2">
            <div className="position-absolute red-btn-up">
              <button className="red-btn promo-btn">{props.special.name}</button>
            </div>
            {props.products && props.products.length > 0 ? (
              <div className="row">
                <div className="col-5 py-5">
                  <div className="w-img">
                    <img
                      src={
                        props.products[0].images &&
                        props.products[0].images.length > 0
                          ? props.products[0].images[0].path
                          : ""
                      }
                    />
                  </div>
                </div>
                <div className="col-7 py-5">
                  <h2 className="title">{props.products[0].name}</h2>
                  <span className="price-now me-2 mt-2">
                    ${props.products[0].variation.new_price}
                  </span>
                  <span className="old-price">
                    ${props.products[0].variation.price}
                  </span>
                  <p className="des mt-2">
                    {props.products[0].short_description}
                  </p>
                  <div className="d-flex align-content-around flex-wrap mt-3">
                    <div className="count-num">
                      <span>{timer.days}</span>
                      <h3>DAYS</h3>
                    </div>
                    <div className="count-num">
                      <span>{timer.hours}</span>
                      <h3>HOURS</h3>
                    </div>
                    <div className="count-num">
                      <span>{timer.minutes}</span>
                      <h3>MINS</h3>
                    </div>
                    <div className="count-num">
                      <span>{timer.seconds}</span>
                      <h3>SEC</h3>
                    </div>
                  </div>
                  <div className="d-flex mt-3">
                    <Link
                      to={`/product/${props.products[0].id}`}
                      className="red-btn"
                    >
                      ADD TO CARD
                    </Link>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        {props.promotion ? (
          <div className="sale">
            <div className="p-3 h-100" style={{ background: "#f2f2f2" }}>
              <h3>{props.promotion.name}</h3>
              <h2>{props.promotion.short_description}</h2>
              <Link to="/search" className="red-btn small">
                SHOP NOW
              </Link>
              <div>
                <div className="tool1">
                  <img
                    width={150}
                    height={150}
                    style={{ maxWidth: "80%", objectFit: "contain" }}
                    className="d-block m-auto mt-4"
                    src={props.promotion.image}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default DealOfDay;
