import config from "../config/config";
import apiWorker from "../utils/Api";

const root = `${config.root}/user`;

const login = async (body) => {
  try {
    const res = await apiWorker().post(root + "/login", body);
    return res;
  } catch (error) {
    return false;
  }
};

const register = async (body) => {
  try {
    const res = await apiWorker().post(root + "/register", body);
    return res;
  } catch (error) {
    return error;
  }
};

const registerAsBuseniss = async (body) => {
  try {
    const res = await apiWorker().post(root + "/busniss-register", body);
    return res;
  } catch (error) {
    return error;
  }
};

const logout = async () => {
  try {
    const res = await apiWorker().post(root + "/logout");
    return res;
  } catch (error) {
    return false;
  }
};

export default { login, logout, register, registerAsBuseniss };
