import {
  imgAngleDown,
  imgCart,
  imgHeadset,
  imgHeart,
  imgLogo,
  imgSearch,
  imgUser,
} from "../utils/Images";
import { Link } from "react-router-dom";
import { useAuth } from "../contexts/Auth.context";
import { useGlobal } from "../contexts/Global.context";
import { useCart } from "../contexts/Cart.context";
import { useState } from "react";

function Navbar() {
  const { user, logout } = useAuth();
  const { categories } = useGlobal();
  const { cart } = useCart();
  const [isLoggingOut, setIsLogginOut] = useState(false);

  const handleLogout = async () => {
    setIsLogginOut(true);
    await logout();
    setIsLogginOut(false);
  };

  return (
    <>
      <header>
        <div className="main-navbar">
          <div className="top-nav">
            <div className="container d-flex align-items-center">
              <div className="left">
                <Link to="/" className="navbar-brand">
                  <img src={imgLogo} />
                </Link>
              </div>

              <div className="mid">
                <form className="search-form">
                  <input placeholder="Search for part" type="search" />
                  <button>
                    <img src={imgSearch} />
                  </button>
                </form>
              </div>

              <div className="right">
                <div className="n-item">
                  <img src={imgHeadset} />
                  <div className="content">
                    <span className="t">Contact Us</span>
                    <span className="b">313-000-0000</span>
                  </div>
                </div>

                <div className="n-item">
                  <img src={imgUser} />
                  <div className="content">
                    <span className="t">My Account</span>
                    {user ? (
                      <>
                        <span className="b">
                          {user.first_name} <img src={imgAngleDown} />
                          <ul
                            className={`dropdown-menu ${
                              isLoggingOut ? "is-loading" : ""
                            }`}
                            aria-labelledby="navbarDropdownMenuLink"
                          >
                            <li>
                              <a className="dropdown-item">My Account</a>
                            </li>
                            <li>
                              <a className="dropdown-item">Track Order</a>
                            </li>
                            <li>
                              <a className="dropdown-item">Order History</a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                onClick={handleLogout}
                              >
                                Logout
                              </a>
                            </li>
                          </ul>
                        </span>
                      </>
                    ) : (
                      <span className="b">
                        <Link to="/login">Login</Link>&nbsp;-&nbsp;
                        <Link to="/register">Register</Link>
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Bot Nav */}
          <div className="bot-nav">
            <nav className="navbar navbar-expand-lg navbar-dark ">
              <div className="container">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    {categories.map((category) => {
                      return category.categories.length > 0 ? (
                        <li
                          className="nav-item dropdown nav-items"
                          key={category.id}
                        >
                          <Link
                            to={`/category/${category.id}`}
                            className="nav-link dropdown-toggle"
                          >
                            {category.name}
                          </Link>
                          <ul className="dropdown-menu">
                            {category.categories.map((sub) => {
                              return (
                                <li key={sub.id}>
                                  <Link
                                    to={
                                      sub.categories.length > 0
                                        ? `/category/${sub.id}`
                                        : `/search?category=${sub.id}`
                                    }
                                    className="dropdown-item"
                                  >
                                    {sub.name}
                                  </Link>
                                </li>
                              );
                            })}
                          </ul>
                        </li>
                      ) : (
                        <li className="nav-itemnav-items" key={category.id}>
                          <Link
                            to={`/search?category=${category.id}`}
                            className="nav-link"
                          >
                            {category.name}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>

                  <div className="right">
                    {user ? (
                      <>
                        <Link to="/wishlist" className="n-item">
                          <img src={imgHeart} />
                          <div className="content">
                            <span className="b">0</span>
                          </div>
                        </Link>

                        <Link to="/cart" className="n-item">
                          <img src={imgCart} />
                          <div className="content">
                            <span className="b">$0.0</span>
                          </div>
                        </Link>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
}

export default Navbar;
