import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Product from "../../components/Product";
import { useGlobal } from "../../contexts/Global.context";
import ProductService from "../../services/Product.service";
import { deepSearchByKey, goTo } from "../../utils/GeneralHelpers";
import { imgLoading3 } from "../../utils/Images";
import FilterSidebar from "./FilterSidebar";

function SearchPage() {
  const [category, setCategory] = useState({});
  const { categories } = useGlobal();
  const [filters, setFilters] = useState(null);
  const [query, setQuery] = useState({});
  const [productsRes, setProductsRes] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [timer, setTimer] = useState(null);
//   const [firstTime, setFirstTime] = useState(true);

  useEffect(() => {
    let params = Object.fromEntries([...searchParams]);

    // if (firstTime) {
    //   params.page = params.page ? params.page : 1;
    //   params.order_by_clauses = params.order_by_clauses
    //     ? params.order_by_clauses
    //     : "OrderByNewest";
    //   params.page_size = params.page_size ? params.page_size : 15;
    //   params.brand = params.brand ? params.brand : "";
    //   params.text = params.text ? params.text : "";
    //   params.car_make = params.car_make ? params.car_make : "";
    //   params.car_model = params.car_model ? params.car_model : "";
    //   params.car_year = params.car_year ? params.car_year : "";
    //   setFirstTime(false);
    //   setTimeout(() => {
    //     setSearchParams({
    //       ...params,
    //     });
    //   }, 150);
    //   return;
    // }

    if (categories.length == 0 || Object.keys(params).length == 0) return;
    if (params.category) {
      let category = deepSearchByKey(categories, "id", params.category);
      if (category.length > 0) {
        category = category[0];
        setCategory(category);
      }
    }

    goTo("#header");

    if (params.brand) {
      params.brand = params.brand.split("-");
    } else {
      params.brand = [];
    }

    setQuery({
      ...params,
    });
  }, [searchParams, categories]);

  useEffect(() => {
    ProductService.filters().then((res) => {
      if (res) {
        setFilters(res.data.result);
      }
    });
  }, []);

  useEffect(() => {
    if (Object.keys(query).length == 0) return;
    const load = async (search) => {
      setProductsRes(null);
      const res = await ProductService.list(search);
      setProductsRes(res.data.result);
    };

    clearTimeout(timer);
    setTimer(
      setTimeout(() => {
        console.log("query", query);
        load(query);
      }, 1000)
    );
  }, [query]);

  const handleChange = (e) => {
    let params = Object.fromEntries([...searchParams]);
    let newParams = { ...params };

    if (e.target.name == "brand") {
      if (!newParams.brand) newParams.brand = "";
      let brand = newParams.brand.split("-");
      brand = brand.filter((b) => b != "");
      if (e.target.checked) {
        brand.push(e.target.value);
      } else {
        brand = brand.filter((b) => b != e.target.value);
      }

      brand = brand.join("-");
      newParams.brand = brand;
    } else {
      newParams[e.target.name] = e.target.value;
    }

    setSearchParams({
      ...newParams,
    });
  };

  return (
    <>
      <div className="container pt-3">
        {/* <p className="total-num">
          Keep your seats in pristine condition with new seat covers from
          4x4Motors.com. Protect your upholstery from dirt, moisture, and food
          spills by fitting your interior with high-quality aftermarket seat
          covers. We have got plenty of options for you to choose from. Use our
          filters to simplify your search. Emjoy fast shipping when you order
          your new seat covers today!
        </p> */}

        <div className="row mt-3">
          {/* <!--sidebar--> */}
          <div className="col-3 text-start sidebar">
            <FilterSidebar
              filters={filters}
              query={query}
              setQuery={setQuery}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              handleChange={handleChange}
            />
          </div>
          <div className="col-9">
            <div className="row">
              <div className="col-12">
                <h2 id="header" className="title">
                  {category ? category.name : ""}
                  <hr />
                </h2>
              </div>
              {/* <div className="col-md-12">
                <h2 className="rder-num mb-3">Shop Catalog</h2>
              </div> */}
            </div>

            {productsRes != null ? (
              <div
                className="d-flex align-items-center"
                style={{ justifyContent: "space-between" }}
              >
                <div>
                  <h3 className="total-num ">
                    <span className="fw-bold">{productsRes.total_items}</span>
                    &nbsp;total items
                  </h3>
                </div>

                <div className="d-flex align-items-center">
                  <span className="me-2 order-font fw-bold">Show:</span>
                  <select
                    className="form-select w-auto"
                    value={query.page_size}
                    name="page_size"
                    onChange={handleChange}
                  >
                    <option value={15}>15 items</option>
                    <option value={30}>30 items</option>
                    <option value={45}>45 items</option>
                  </select>

                  <span className="ms-4 me-2 order-font fw-bold">Sort by:</span>
                  <select
                    className="form-select w-auto"
                    value={query.order_by_clauses}
                    name="order_by_clauses"
                    onChange={handleChange}
                  >
                    {filters
                      ? filters.order_by_clauses.map((sort) => {
                          return (
                            <option key={sort} value={sort}>
                              {sort}
                            </option>
                          );
                        })
                      : ""}
                  </select>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="products-box mt-3 pt-3">
              {productsRes == null ? (
                <img src={imgLoading3} className="d-block m-auto" height="80" />
              ) : (
                productsRes.items.map((product) => {
                  return <Product key={product.id} item={product} />;
                })
              )}
            </div>

            <div>
              <div className="d-flex align-items-center justify-content-between w-50 m-auto my-3">
                <button className="previous">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.75 5L8.75 15L18.75 25"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <div className="d-flex">
                  <button className="select-btn page-active">1</button>
                  <button className="select-btn">2</button>
                  <button className="select-btn">3</button>
                </div>
                <button className="next">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 5L20 15L10 25"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchPage;
