import { Link } from "react-router-dom";
import { useCart } from "../../contexts/Cart.context";

function AddedToCart(props) {
  const { cart } = useCart();
  return (
    <>
      <div
        className={`modal added-modal fade ${props.show ? "show" : ""}`}
        tabIndex="-1"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title fw-bold">Item added to your cart</h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  props.setShow(false);
                }}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="left">
                    <img
                      className="m-product-img"
                      src="https://i.ebayimg.com/00/s/MTA2NlgxNjAw/z/FvAAAOSwC61dUsD8/$_57.JPG?set_id=8800005007"
                    />
                    <div className="content">
                      <span className="name">{props.product.name}</span>

                      <span className="op1">
                        Price: ${props.variation.new_price}
                      </span>
                      <span className="op1">
                        Quantity: {props.cartItem.quantity}
                      </span>
                      <span className="op1 fw-bold mt-2">
                        Subtotal: $
                        {props.variation.new_price * props.cartItem.quantity}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <h5 className="mb-3">
                    Cart Total: {cart.items.length} items (${cart.total_price})
                  </h5>
                  <div>
                    <Link
                      onClick={() => {
                        props.setShow(false);
                      }}
                      to="/cart"
                      className="btn my-0 btn-submit w-100"
                    >
                      View CART
                    </Link>
                  </div>

                  <div>
                    <button
                      onClick={() => {
                        props.setShow(false);
                      }}
                      className="mt-3 btn btn2 my-0 btn-submit-nobg big-btn w-100"
                    >
                      CONTINUE SHOPPING
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {props.show ? (
        <div
          onClick={() => {
            props.setShow(false);
          }}
          className={`modal-backdrop fade show`}
        ></div>
      ) : (
        ""
      )}
    </>
  );
}

export default AddedToCart;
