import { useRef } from "react";
import Slider from "react-slick";
import { imgLoading3 } from "../utils/Images";
import Product from "./Product";

function ProductSlider(props) {
  const slickRef = useRef();

  const settings = {
    infinite: true,
    slidesToShow: 4,
    speed: 1000,
    arrows: false,
    autoplay: true,
  };

  const getSlides = props.items.map((item, i) => {
    return (
      <div key={i}>
        <Product item={item} />
      </div>
    );
  });

  const handleNext = () => {
    if (slickRef) {
      slickRef.current.slickNext();
    }
  };

  const handlePrev = () => {
    if (slickRef) {
      slickRef.current.slickPrev();
    }
  };

  return (
    <>
      {/* <!-- Slider Special Products --> */}
      <div className="slider">
        <div className="top-rated">
          <div className="container">
            <h2>{props.header}</h2>
            <div className="buttons-prev-next d-flex justify-content-end align-items-center">
              <button
                className="slick-prev slick-arrow"
                onClick={handlePrev}
                type="button"
                style={{ display: "inline-block" }}
              >
                <svg
                  width="12"
                  height="20"
                  viewBox="0 0 12 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 2L2 10L10 18"
                    stroke="#8F8F8F"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
              <button
                className="slick-next slick-arrow"
                onClick={handleNext}
                type="button"
                style={{ display: "inline-block" }}
              >
                <svg
                  width="12"
                  height="20"
                  viewBox="0 0 12 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 2L10 10L2 18"
                    stroke="#8F8F8F"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
            {props.items.length > 0 ? (
              <Slider {...settings} ref={slickRef} className="slide">
                {getSlides}
              </Slider>
            ) : (
              <img src={imgLoading3} className="d-block m-auto" height="80" />
            )}
          </div>
        </div>
      </div>

      <div className="text-center">
        <hr className="hr-slider" />
      </div>
    </>
  );
}

export default ProductSlider;
