import { useEffect, useState } from "react";
import GlobalService from "../../services/Global.service";

const FilterSidebar = (props) => {
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);
  const [years, setYears] = useState([]);
  const [isCarLoading, setIsCarLoading] = useState([-1, -1, -1]);

  useEffect(() => {
    const loadCarMMY = async () => {
      let params = Object.fromEntries([...props.searchParams]);
      const makeRes = await GlobalService.makes();
      setMakes(makeRes.data.result.items);

      if (params.car_make) {
        const modelRes = await GlobalService.models(params.car_make);
        setModels(modelRes.data.result.items);
      } else {
        setModels([]);
      }

      if (params.car_model) {
        const yearRes = await GlobalService.years(params.car_model);
        setYears(yearRes.data.result.items);
      } else {
        setYears([]);
      }

      setIsCarLoading([0, 0, 0]);
    };

    setIsCarLoading([1, -1, -1]);
    loadCarMMY();
  }, [props.query.category]);

  const handleCarChange = async (e) => {
    let params = Object.fromEntries([...props.searchParams]);
    let newParams = { ...params };
    switch (e.target.name) {
      case "car_make":
        newParams.car_make = e.target.value ? e.target.value : "";
        newParams.car_model = "";
        newParams.car_year = "";
        setIsCarLoading([-1, 1, -1]);
        if (e.target.value) {
          const mres = await GlobalService.models(e.target.value);
          setModels(mres.data.result.items);
        } else {
          setModels([]);
        }
        setYears([]);
        break;

      case "car_model":
        newParams.car_model = e.target.value ? e.target.value : "";
        newParams.car_year = "";
        setIsCarLoading([-1, -1, 1]);
        if (e.target.value) {
          const yres = await GlobalService.years(e.target.value);
          setYears(yres.data.result.items);
        } else {
          setYears([]);
        }
        break;

      case "car_year":
        newParams.car_year = e.target.value;
        break;
    }

    setIsCarLoading([0, 0, 0]);
    props.setSearchParams({
      ...newParams,
    });
  };

  return (
    <>
      <div className="input-group mb-4 mt-2">
        <button
          className="input-group-text border-end-0 d-flex align-items-center justify-content-center"
          style={{ width: "45px" }}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 29 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M28.0898 26.2065L19.2134 17.3301C20.5908 15.5493 21.3359 13.3721 21.3359 11.082C21.3359 8.34082 20.2661 5.77051 18.3315 3.83252C16.397 1.89453 13.8198 0.828125 11.082 0.828125C8.34424 0.828125 5.76709 1.89795 3.83252 3.83252C1.89453 5.76709 0.828125 8.34082 0.828125 11.082C0.828125 13.8198 1.89795 16.397 3.83252 18.3315C5.76709 20.2695 8.34082 21.3359 11.082 21.3359C13.3721 21.3359 15.5459 20.5908 17.3267 19.2168L26.2031 28.0898C26.2292 28.1159 26.2601 28.1365 26.2941 28.1506C26.3281 28.1647 26.3645 28.172 26.4014 28.172C26.4382 28.172 26.4746 28.1647 26.5087 28.1506C26.5427 28.1365 26.5736 28.1159 26.5996 28.0898L28.0898 26.603C28.1159 26.577 28.1365 26.5461 28.1506 26.5121C28.1647 26.4781 28.172 26.4416 28.172 26.4048C28.172 26.368 28.1647 26.3315 28.1506 26.2975C28.1365 26.2635 28.1159 26.2326 28.0898 26.2065ZM16.4961 16.4961C15.0469 17.9419 13.126 18.7383 11.082 18.7383C9.03809 18.7383 7.11719 17.9419 5.66797 16.4961C4.22217 15.0469 3.42578 13.126 3.42578 11.082C3.42578 9.03809 4.22217 7.11377 5.66797 5.66797C7.11719 4.22217 9.03809 3.42578 11.082 3.42578C13.126 3.42578 15.0503 4.21875 16.4961 5.66797C17.9419 7.11719 18.7383 9.03809 18.7383 11.082C18.7383 13.126 17.9419 15.0503 16.4961 16.4961Z"
              fill="black"
            />
          </svg>
        </button>
        <input
          type="text"
          className="form-control input-style px-2 border-start-0"
          placeholder="Search by part name"
          value={props.query.text}
          name="text"
          onChange={props.handleChange}
        />
      </div>

      <div className="mt-3">
        <span className="order-font fw-bold">Car Make</span>
      </div>
      <select
        value={props.query.car_make ? props.query.car_make : ""}
        name="car_make"
        className={`form-control ${isCarLoading[0] == 1 ? "is-loading" : ""}`}
        disabled={isCarLoading[0] == -1}
        onChange={handleCarChange}
      >
        <option value="">All Makes</option>
        {makes
          ? makes.map((make) => {
              return (
                <option key={make.id} value={make.id}>
                  {make.name}
                </option>
              );
            })
          : ""}
      </select>

      <div className="mt-3">
        <span className="order-font fw-bold">Car Model</span>
      </div>
      <select
        value={props.query.car_model ? props.query.car_model : ""}
        name="car_model"
        className={`form-control ${isCarLoading[1] == 1 ? "is-loading" : ""}`}
        disabled={isCarLoading[1] == -1}
        onChange={handleCarChange}
      >
        <option value="">All Models</option>
        {models && props.query.car_make
          ? models.map((model) => {
              return (
                <option key={model.id} value={model.id}>
                  {model.name}
                </option>
              );
            })
          : ""}
      </select>

      <div className="mt-3">
        <span className="order-font fw-bold">Model Year</span>
      </div>
      <select
        value={props.query.car_year ? props.query.car_year : ""}
        name="car_year"
        className={`form-control ${isCarLoading[2] == 1 ? "is-loading" : ""}`}
        disabled={isCarLoading[2] == -1}
        onChange={handleCarChange}
      >
        <option value="">All Years</option>
        {years && props.query.car_model
          ? years.map((year) => {
              return (
                <option key={year.id} value={year.id}>
                  {year.year}
                </option>
              );
            })
          : ""}
      </select>

      {/* <!--brand--> */}
      <div className="mt-3">
        <span className="order-font fw-bold">Brands</span>
      </div>
      <div className="options-box mt-1">
        {props.query.brand &&
          props.filters &&
          props.filters.brands.map((option) => {
            return (
              <label className="option-item" key={option.id}>
                <div className="left">
                  <input
                    className="form-check-input me-2 mt-0"
                    type="checkbox"
                    value={option.id}
                    name="brand"
                    checked={props.query.brand.includes(option.id.toString())}
                    onChange={props.handleChange}
                  />
                  <span className="total-num me-2">{option.name}</span>
                </div>
                {/* <div className="right">
                  <span className="map">(25)</span>
                </div> */}
              </label>
            );
          })}
      </div>
    </>
  );
};

export default FilterSidebar;
