import { useEffect, useState } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import { useAuth } from "../../contexts/Auth.context";
import authService from "../../services/Auth.service";
import FormHandler from "../../utils/Handlers";
import { setFormLoading } from "../../utils/UiHelpers";
import { Link, useNavigate } from "react-router-dom";

function LoginPage() {
  const navigate = useNavigate();
  const { setUser, setToken } = useAuth();
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const formHandler = new FormHandler(form, setForm);

  useEffect(() => {
    setError("");
  }, [form]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setFormLoading(e.target);
    let res = await authService.login(form);
    if (res) {
      if (res.data.ok == true) {
        if (res.data.token) {
          setUser(res.data.user);
          setToken(res.data.token);
          navigate("/");
        } else {
          setError("Your account is under review and not approved yet.");
        }
      } else {
        setError(res.data.message);
      }
    } else {
      setError("error");
    }
    setFormLoading(e.target, false);
  };

  return (
    <>
      <div className="container">
        <Breadcrumb />
        <h2 className="title"> Login</h2>
        <div className="py-5 w-50 text-center m-auto">
          <div className="d-flex mb-3 align-items-center">
            <span className="me-2">
              <svg
                width="35"
                height="35"
                viewBox="0 0 35 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.1677 17.5001C15.8462 17.5001 17.0844 16.262 17.0844 14.5834C17.0844 12.9049 15.8462 11.6667 14.1677 11.6667C12.4892 11.6667 11.251 12.9049 11.251 14.5834C11.251 16.262 12.4877 17.5001 14.1677 17.5001Z"
                  fill="#676767"
                />
                <path
                  d="M29.1667 5.83325H5.83333C4.22479 5.83325 2.91666 7.05971 2.91666 8.56763V26.4322C2.91666 27.9401 4.22479 29.1666 5.83333 29.1666H29.1667C30.7752 29.1666 32.0833 27.9401 32.0833 26.4322V8.56763C32.0833 7.05971 30.7752 5.83325 29.1667 5.83325ZM29.1667 26.2499L5.83333 26.2339V8.74992L29.1667 8.76596V26.2499Z"
                  fill="#676767"
                />
                <path
                  d="M20.4167 13.125H26.25V16.0417H20.4167V13.125ZM21.875 18.9583H26.25V21.875H21.875V18.9583ZM19.5854 22.6567C19.5854 20.6529 17.1413 18.5937 14.1677 18.5937C11.1942 18.5937 8.75 20.6529 8.75 22.6567V23.3333H19.5854V22.6567Z"
                  fill="#676767"
                />
              </svg>
            </span>
            <h3 className="form-title mb-0">Sign In</h3>
          </div>
          <form onSubmit={handleSubmit}>
            <span className="text-danger text-start d-block fw-bold mb-3">
              {error}
            </span>

            <input
              autoFocus
              required
              name="email"
              type="email"
              placeholder="Email Address"
              className="form-control input-style mb-3"
              value={form.email}
              onChange={formHandler.handleTextChange}
            />
            <input
              required
              name="password"
              type="password"
              placeholder="Password"
              className="form-control input-style mb-3"
              value={form.password}
              onChange={formHandler.handleTextChange}
            />
            <div className="text-end mb-3">
              <a
                href="forgetpasswowd.html"
                className="forgot-password text-decoration-none"
              >
                Forgot Password?
              </a>
            </div>
            <button type="submit" className="btn mb-4 my-4 btn-submit">
              SIGN IN
            </button>
            <div className="new-account">
              Don’t have an account?
              <Link to="/register"> Create an account</Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default LoginPage;
