import { useContext, useEffect, useState, createContext } from "react";
import AuthService from "../services/Auth.service";

const ThisContext = createContext();

export function useAuth() {
  return useContext(ThisContext);
}

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);

  useEffect(() => {
    const local_user = localStorage.getItem("user") ?? null;
    if (local_user) {
      setUser(JSON.parse(local_user));
    }
    let local_token = localStorage.getItem("token") ?? null;
    if (local_token) {
      setToken(local_token);
    }
  }, []);

  useEffect(() => {
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
    }
  }, [user]);

  useEffect(() => {
    if (token) {
      localStorage.setItem("token", token);
    }
  }, [token]);

  const isAuth = () => {
    return token != null && user != null;
  };

  const logout = async () => {
    await AuthService.logout();
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    setUser(null);
    setToken(null);
  };

  return (
    <ThisContext.Provider
      value={{ user, setUser, token, setToken, isAuth, logout }}
    >
      {children}
    </ThisContext.Provider>
  );
};
