import { useEffect, useState } from "react";
import { useWishlist } from "../../contexts/Wishlist.context";

const WishlistItem = (props) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [item, setItem] = useState(null);
  const { removeFromWishlist } = useWishlist();

  useEffect(() => {
    if (isDeleting) {
      removeFromWishlist(item.id);
    }
  }, [isDeleting]);

  useEffect(() => {
    setItem(props.item);
  }, []);

  return (
    <>
      {item ? (
        <tr>
          <td>
            <div className="d-flex align-items-center my-2">
              <img
                src={item.product.image.path}
                width="60"
                height="60"
                style={{ flexShrink: 0, objectFit: "contain" }}
                className="me-2"
              />

              <div className="text-start">
                <b>{item.variation.sku ? item.variation.sku : ""}</b>
                <p className="mb-0 pe-1">{item.product.name}</p>
              </div>
            </div>
          </td>
          {/* <td>{item.product.oem_pn ? item.product.oem_pn : ""}</td> */}
          <td>${item.variation.price}</td>
          <td>
            <button
              onClick={() => {
                setIsDeleting(true);
              }}
              className={`heart p-1 mx-3 ${isDeleting ? "is-loading" : ""}`}
            >
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 10.8008H13.2V20.4008H12V10.8008ZM14.4 10.8008H15.6V20.4008H14.4V10.8008ZM16.8 10.8008H18V20.4008H16.8V10.8008ZM7.19995 7.20078H22.8V8.40078H7.19995V7.20078ZM18 7.20078H16.8V6.60078C16.8 6.24078 16.56 6.00078 16.2 6.00078H13.8C13.44 6.00078 13.2 6.24078 13.2 6.60078V7.20078H12V6.60078C12 5.58078 12.78 4.80078 13.8 4.80078H16.2C17.22 4.80078 18 5.58078 18 6.60078V7.20078Z"
                  fill="#AFAFAF"
                />
                <path
                  d="M18.5999 24.0002H11.3999C10.4399 24.0002 9.5999 23.2202 9.4799 22.2602L8.3999 7.86023L9.5999 7.74023L10.6799 22.1402C10.6799 22.5002 11.0399 22.8002 11.3999 22.8002H18.5999C18.9599 22.8002 19.2599 22.5002 19.3199 22.1402L20.3999 7.74023L21.5999 7.86023L20.5199 22.2602C20.3999 23.2202 19.5599 24.0002 18.5999 24.0002Z"
                  fill="#AFAFAF"
                />
              </svg>
            </button>
          </td>
        </tr>
      ) : (
        ""
      )}
    </>
  );
};

export default WishlistItem;
