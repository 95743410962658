import { useState } from "react";
import { Link } from "react-router-dom";
import config from "../config/config";
import { useAuth } from "../contexts/Auth.context";
import { useCart } from "../contexts/Cart.context";
import { useGlobal } from "../contexts/Global.context";
import { useWishlist } from "../contexts/Wishlist.context";

const Product = (props) => {
  const [isPostingCart, setIsPostingCart] = useState(false);
  const [isPostingWishlist, setIsPostingWishlist] = useState(false);
  const { addToCart } = useCart();
  const { isAuth } = useAuth();
  const { showModal } = useGlobal();
  const { addRemoveWishlist, getInWishlist } = useWishlist();

  let img = props.item.images.filter((i) => i.is_default == 1);
  if (img.length > 0) img = img[0].path;
  else if (props.item.images.length > 0) img = props.item.images[0].path;
  else img = "";

  const handleAddToCart = async () => {
    if (!isAuth()) {
      showModal("goLogin");
      return;
    }

    if (isPostingCart) return;
    setIsPostingCart(true);
    console.log(props.item);
    const res = await addToCart({
      type: 1,
      product_id: props.item.id,
      variation_id: props.item.variation.id,
      company_id: config.defaultCompanyId,
      branch_id: config.defaultCompanyId,
      quantity: 1,
      modifierGroup: [],
    });
    setIsPostingCart(false);
    console.log(res);
  };

  const handleWishlistBtn = async () => {
    if (!isAuth()) {
      showModal("goLogin");
      return;
    }
    if (isPostingWishlist) return;
    setIsPostingWishlist(true);
    const res = await addRemoveWishlist(props.item.id, props.item.variation.id);
    setIsPostingWishlist(false);
  };

  return (
    <>
      <div className="product-item card">
        <Link
          to={`/product/${props.item.id}`}
          className="card-header bg-transparent border-0"
        >
          <span className="product-brand">
            {props.item.brand ? props.item.brand.name : ""}
          </span>
          <span className="me-auto product-des">{props.item.name}</span>
          {/* <span className="badge bg-dark">NEW</span> */}
        </Link>
        <Link
          to={`/product/${props.item.id}`}
          className="card-body text-center"
        >
          <div className="position-relative">
            <img src={img} />
          </div>
        </Link>
        <div className="card-footer bg-transparent border-0 m-0 p-0 d-flex mb-3 justify-content-start align-items-center">
          <div className="price text-center">${props.item.variation.price}</div>
          {props.item.variation.in_stock == 1 &&
          props.item.variation.available_quantity > 0 ? (
            <>
              <button
                type="button"
                onClick={handleAddToCart}
                className={`btn addtocardbtn ${
                  isPostingCart ? "is-loading" : ""
                }`}
              >
                ADD TO CARD
              </button>
            </>
          ) : (
            <button type="button" disabled className={`btn addtocardbtn`}>
              OUT OF STOCK
            </button>
          )}

          <button
            onClick={handleWishlistBtn}
            className={`fav-btn ${isPostingWishlist ? "is-loading" : ""} ${
              getInWishlist(props.item.id, props.item.variation.id)
                ? "active"
                : ""
            }`}
          >
            <svg
              width="22"
              height="20"
              viewBox="0 0 22 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 1C3.239 1 1 3.216 1 5.95C1 8.157 1.875 13.395 10.488 18.69C10.6423 18.7839 10.8194 18.8335 11 18.8335C11.1806 18.8335 11.3577 18.7839 11.512 18.69C20.125 13.395 21 8.157 21 5.95C21 3.216 18.761 1 16 1C13.239 1 11 4 11 4C11 4 8.761 1 6 1Z"
                stroke="#8F8F8F"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
    </>
  );
};

export default Product;
