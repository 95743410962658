import { useState } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import { useWishlist } from "../../contexts/Wishlist.context";
import { imgLoading3 } from "../../utils/Images";
import WishlistItem from "./WishlistItem";

const WishlistPage = () => {
  const { wishlist } = useWishlist();
  const [isEditinWishlist, setIsEditinWishlist] = useState(false);

  return (
    <div className="order py-5">
      <div className="container">
        <Breadcrumb />
        <h2 className="title">My Wishlist</h2>

        {!wishlist ? (
          <img src={imgLoading3} className="d-block m-auto" height="80" />
        ) : (
          ""
        )}

        {wishlist ? (
          wishlist.items.length == 0 ? (
            <div className="empty-box">
              <svg
                width="40"
                height="40"
                viewBox="0 0 22 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 1C3.239 1 1 3.216 1 5.95C1 8.157 1.875 13.395 10.488 18.69C10.6423 18.7839 10.8194 18.8335 11 18.8335C11.1806 18.8335 11.3577 18.7839 11.512 18.69C20.125 13.395 21 8.157 21 5.95C21 3.216 18.761 1 16 1C13.239 1 11 4 11 4C11 4 8.761 1 6 1Z"
                  stroke="#8F8F8F"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>Wishlist is Empty</span>
            </div>
          ) : (
            <div className={`py-5 text-start m-auto`}>
              <div className="order-box px-4 py-3">
                <div id="order_details" className="order-details d-block mt-3">
                  <div className="my-3">
                    <table
                      className="text-center w-100"
                      style={{
                        tableLayout: "fixed",
                        borderCollapse: "collapse",
                      }}
                    >
                      <thead>
                        <tr>
                          <th className="text-start" style={{ width: "45%" }}>
                            Item Name
                          </th>
                          {/* <th>SKU</th> */}
                          <th>Unit Price</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {wishlist && wishlist.items
                          ? wishlist.items.map((item) => {
                              return (
                                <WishlistItem
                                  isEditinWishlist={isEditinWishlist}
                                  setIsEditinWishlist={setIsEditinWishlist}
                                  key={item.id}
                                  item={item}
                                />
                              );
                            })
                          : ""}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default WishlistPage;
