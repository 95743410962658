import { useContext, useEffect, useState, createContext } from "react";
import HomeService from "../services/Home.service";

const ThisContext = createContext();

export function useGlobal() {
  return useContext(ThisContext);
}

export const GlobalProvider = ({ children }) => {
  const [categories, setCategories] = useState([]);
  const [homeSliders, setHomeSliders] = useState([]);
  const [modals, setModals] = useState({
    goLogin: false,
  });

  const showModal = (name) => {
    setModals({
      ...modals,
      [name]: true,
    });
  };

  const hideModal = (name) => {
    setModals({
      ...modals,
      [name]: false,
    });
  };

  useEffect(() => {
    HomeService.home().then((res) => {
      setCategories(res.data.result.all_categories);
      setHomeSliders(res.data.result.slider);
    });
  }, []);

  return (
    <ThisContext.Provider
      value={{
        categories,
        setCategories,
        homeSliders,
        modals,
        hideModal,
        showModal,
      }}
    >
      {children}
    </ThisContext.Provider>
  );
};
