import { useEffect, useState } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import { useAuth } from "../../contexts/Auth.context";
import authService from "../../services/Auth.service";
import FormHandler from "../../utils/Handlers";
import { setFormLoading } from "../../utils/UiHelpers";
import parse from "html-react-parser";
import { Link, useNavigate } from "react-router-dom";
import GlobalService from "../../services/Global.service";

function BusinessRegisterPage() {
  const navigate = useNavigate();
  const { setUser, setToken } = useAuth();
  const [form, setForm] = useState({
    email: "",
    password: "",
    confirm_password: "",
    is_agreed: "",
    first_name: "",
    last_name: "",
    country_id: "",
    state_id: "",
    city_id: "",
    post_code: "",
  });
  const formHandler = new FormHandler(form, setForm);

  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState([-1, -1, -1]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    setIsLoading([1, -1, -1]);
    GlobalService.countries().then((res) => {
      setCountries(res.data.result);
      setIsLoading([0, 0, 0]);
    });
  }, []);

  useEffect(() => {
    setError("");
  }, [form]);

  const handleCSCChange = async (e) => {
    let newForm = { ...form };
    switch (e.target.name) {
      case "country_id":
        newForm.country_id = e.target.value;
        newForm.state_id = null;
        newForm.city_id = null;
        setIsLoading([-1, 1, -1]);
        const mres = await GlobalService.states(e.target.value);
        setCities([]);
        setStates(mres.data.result);
        break;

      case "state_id":
        newForm.state_id = e.target.value;
        newForm.city_id = null;
        setIsLoading([-1, -1, 1]);
        const yres = await GlobalService.cities(e.target.value);
        setCities(yres.data.result);
        break;

      case "city_id":
        newForm.city_id = e.target.value;
        break;
    }

    setIsLoading([0, 0, 0]);
    setForm({
      ...newForm,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    if (form.password != form.confirm_password) {
      setError("Confirm password not match");
      return;
    }
    setFormLoading(e.target);
    let res = await authService.registerAsBuseniss(form);
    if (res && res.data) {
      if (res.data.success == true) {
        alert(res.data.message);
        navigate("/login")
      } else {
        setError(res.data.message);
      }
    } else {
      if (res.response && res.response.data && res.response.data.message) {
        const data = res.response.data;
        let error = data.message;

        if (data.errors) {
          Object.keys(data.errors).forEach((err) => {
            data.errors[err].forEach((str_er) => {
              error += "<br />" + str_er;
            });
          });
        }

        setError(error);
      } else {
        setError("Error");
      }
    }
    setFormLoading(e.target, false);
  };

  return (
    <>
      <div className="container">
        <Breadcrumb />
        <h2 className="title"> Business Account</h2>
        <div className="py-5 w-50 m-auto">
          <div className="d-flex mb-3 align-items-center">
            <span className="me-2">
              <svg
                width="35"
                height="35"
                viewBox="0 0 35 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.1677 17.5001C15.8462 17.5001 17.0844 16.262 17.0844 14.5834C17.0844 12.9049 15.8462 11.6667 14.1677 11.6667C12.4892 11.6667 11.251 12.9049 11.251 14.5834C11.251 16.262 12.4877 17.5001 14.1677 17.5001Z"
                  fill="#676767"
                />
                <path
                  d="M29.1667 5.83325H5.83333C4.22479 5.83325 2.91666 7.05971 2.91666 8.56763V26.4322C2.91666 27.9401 4.22479 29.1666 5.83333 29.1666H29.1667C30.7752 29.1666 32.0833 27.9401 32.0833 26.4322V8.56763C32.0833 7.05971 30.7752 5.83325 29.1667 5.83325ZM29.1667 26.2499L5.83333 26.2339V8.74992L29.1667 8.76596V26.2499Z"
                  fill="#676767"
                />
                <path
                  d="M20.4167 13.125H26.25V16.0417H20.4167V13.125ZM21.875 18.9583H26.25V21.875H21.875V18.9583ZM19.5854 22.6567C19.5854 20.6529 17.1413 18.5937 14.1677 18.5937C11.1942 18.5937 8.75 20.6529 8.75 22.6567V23.3333H19.5854V22.6567Z"
                  fill="#676767"
                />
              </svg>
            </span>
            <h3 className="form-title mb-0">Create Business Account</h3>
          </div>
          <form onSubmit={handleSubmit}>
            <span className="text-danger text-start d-block fw-bold mb-3">
              {parse(error)}
            </span>

            <div className="row">
              <div className="col-md-6">
                <label className="form-label text-start ms-2 position-relative">
                  First Name
                  <span className="position-absolute translate-middle p-2 ms-2 icon">
                    *
                  </span>
                </label>
                <input
                  autoFocus
                  required
                  name="first_name"
                  type="text"
                  className="form-control input-style mb-3"
                  value={form.first_name}
                  onChange={formHandler.handleTextChange}
                />
              </div>

              <div className="col-md-6">
                <label className="form-label text-start ms-2 position-relative">
                  Last Name
                  <span className="position-absolute translate-middle p-2 ms-2 icon">
                    *
                  </span>
                </label>
                <input
                  required
                  name="last_name"
                  type="text"
                  className="form-control input-style mb-3"
                  value={form.last_name}
                  onChange={formHandler.handleTextChange}
                />
              </div>
            </div>

            <label className="form-label text-start ms-2 position-relative">
              Email Address
              <span className="position-absolute translate-middle p-2 ms-2 icon">
                *
              </span>
            </label>
            <input
              required
              name="email"
              type="email"
              className="form-control input-style mb-3"
              value={form.email}
              onChange={formHandler.handleTextChange}
            />

            <label className="form-label text-start ms-2 position-relative">
              Tax ID
              <span className="position-absolute translate-middle p-2 ms-2 icon">
                *
              </span>
            </label>
            <input
              required
              name="tax_id"
              type="text"
              className="form-control input-style mb-3"
              value={form.tax_id}
              onChange={formHandler.handleTextChange}
            />

            <label className="form-label text-start ms-2 position-relative">
              Company Name
              <span className="position-absolute translate-middle p-2 ms-2 icon">
                *
              </span>
            </label>
            <input
              required
              name="company_name"
              type="text"
              className="form-control input-style mb-3"
              value={form.company_name}
              onChange={formHandler.handleTextChange}
            />

            <div className="row">
              <div className="col-md-6">
                <label className="form-label text-start ms-2 position-relative">
                  Country
                  <span className="position-absolute translate-middle p-2 ms-2 icon">
                    *
                  </span>
                </label>
                <select
                  required
                  name="country_id"
                  value={form.country_id}
                  onChange={handleCSCChange}
                  className={`form-control input-style mb-3 ${
                    isLoading[0] == 1 ? "is-loading" : ""
                  }`}
                  disabled={isLoading[0] == -1}
                >
                  <option hidden value="">
                    Select Country
                  </option>
                  {countries
                    ? countries.map((c) => {
                        return (
                          <option key={c.id} value={c.id}>
                            {c.name}
                          </option>
                        );
                      })
                    : ""}
                </select>
              </div>

              <div className="col-md-6">
                <label className="form-label text-start ms-2 position-relative">
                  State
                  <span className="position-absolute translate-middle p-2 ms-2 icon">
                    *
                  </span>
                </label>
                <select
                  required
                  name="state_id"
                  value={form.state_id}
                  onChange={handleCSCChange}
                  className={`form-control input-style mb-3 ${
                    isLoading[1] == 1 ? "is-loading" : ""
                  }`}
                  disabled={isLoading[1] == -1}
                >
                  <option value="" hidden>
                    Select State
                  </option>
                  {states
                    ? states.map((c) => {
                        return (
                          <option key={c.id} value={c.id}>
                            {c.name}
                          </option>
                        );
                      })
                    : ""}
                </select>
              </div>

              <div className="col-md-6">
                <label className="form-label text-start ms-2 position-relative">
                  City
                  <span className="position-absolute translate-middle p-2 ms-2 icon">
                    *
                  </span>
                </label>
                <select
                  required
                  name="city_id"
                  value={form.city_id}
                  onChange={handleCSCChange}
                  className={`form-control input-style mb-3 ${
                    isLoading[2] == 1 ? "is-loading" : ""
                  }`}
                  disabled={isLoading[2] == -1}
                >
                  <option value="" hidden>
                    Select City
                  </option>
                  {cities
                    ? cities.map((c) => {
                        return (
                          <option key={c.id} value={c.id}>
                            {c.name}
                          </option>
                        );
                      })
                    : ""}
                </select>
              </div>

              <div className="col-md-6">
                <label className="form-label text-start ms-2 position-relative">
                  Post Code
                  <span className="position-absolute translate-middle p-2 ms-2 icon">
                    *
                  </span>
                </label>
                <input
                  required
                  name="post_code"
                  type="number"
                  className="form-control input-style mb-3"
                  value={form.post_code}
                  onChange={formHandler.handleTextChange}
                />
              </div>
            </div>

            <label className="form-label text-start ms-2 position-relative">
              Password
              <span className="position-absolute translate-middle p-2 ms-2 icon">
                *
              </span>
            </label>
            <input
              required
              name="password"
              type="password"
              className="form-control input-style mb-3"
              value={form.password}
              onChange={formHandler.handleTextChange}
            />

            <label className="form-label text-start ms-2 position-relative">
              Confirm Password
              <span className="position-absolute translate-middle p-2 ms-2 icon">
                *
              </span>
            </label>
            <input
              required
              name="confirm_password"
              type="password"
              className="form-control input-style mb-3"
              value={form.confirm_password}
              onChange={formHandler.handleTextChange}
            />

            <label className="form-label text-start ms-2 position-relative">
              Attach Your TAX ID Document
              <span className="position-absolute translate-middle p-2 ms-2 icon">
                *
              </span>
            </label>
            <input
              required
              name="tax_id_document"
              type="file"
              className="form-control input-style mb-3"
              value={form.tax_id_document}
              onChange={formHandler.tax_id_document}
            />

            <div className="mb-3 d-flex">
              <label className="form-check ">
                <input
                  required
                  name="is_agreed"
                  className="form-check-input"
                  type="checkbox"
                  checked={form.is_agreed}
                  onChange={formHandler.handleTextChange}
                />
                <span className="agree">
                  By creating an account, you agree to our
                  <a href="">&nbsp;Terms & Conditions&nbsp;</a>and
                  <a href="">&nbsp;Privacy Policy&nbsp;</a>
                </span>
              </label>
            </div>
            <div className="text-center">
              <button
                disabled={!form.is_agreed}
                type="submit"
                className="btn mb-4 mt-0 btn-submit big-btn"
              >
                CREATE ACCOUNT
              </button>
              <br />
              <Link
                to="/register"
                className="btn btn2 mb-4 mt-0 btn-submit-nobg big-btn"
              >
                CREATE INDIVIDUAL ACCOUNT
              </Link>
              <div className="new-account">
                Already have an account?
                <Link to="/login"> Sign In</Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default BusinessRegisterPage;
