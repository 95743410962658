import { Link } from "react-router-dom";
import { useGlobal } from "../contexts/Global.context";
import { imgHomeVideo } from "../utils/Images";

function HomeSlider() {
  const { homeSliders } = useGlobal();

  return (
    <>
      {homeSliders && homeSliders.length > 0 ? (
        <div className="home">
          <div className="imghome position-relative">
            <img
              className="w-100 h-100"
              style={{ objectFit: "cover", objectPosition: "center" }}
              src={homeSliders[0].img}
              alt=""
            />
            <div className="description">
              <div className="desc1">{homeSliders[0].title}</div>
              <div className="desc2">{homeSliders[0].description}</div>
              <Link
                to={`/product/${homeSliders[0].product_id}`}
                className="btn btn-home btn-lg"
              >
                {homeSliders[0].button_text}
              </Link>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default HomeSlider;
